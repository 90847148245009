import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit(e) {
    document.getElementById("tab_report_type").value = this.element.value;
    document.getElementById("submit_select_date_and_report_type").click();

    let tab_spinners = document.getElementsByClassName("tab-spinner");
    for (let i = 0; i < tab_spinners.length; i++) {
      tab_spinners[i].classList.remove("hidden");
    }
  }
}
