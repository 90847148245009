import { Controller } from "@hotwired/stimulus";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
export default class extends Controller {
  static targets = ["picker"];

  connect() {
    flatpickr(this.pickerTarget, {
      onChange: (selectedDates, dateStr, instance) => {},
      plugins: [
        ShortcutButtonsPlugin({
          button: [
            { label: "Today" },
            { label: "This Week" },
            { label: "This Month" },
          ],
          onClick: (index, fp) => {
            if (index === 0) {
              // today
              let date = new Date();
              fp.setDate([date, date], true);
              fp.close();
            } else if (index === 1) {
              // this week
              let prevMonday = new Date();
              let prevSunday = new Date();
              prevMonday.setDate(
                prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7),
              );
              prevSunday.setDate(prevMonday.getDate() + 6);
              fp.setDate([prevMonday, prevSunday], true);
              fp.close();
            } else if (index === 2) {
              // this month
              let currentMonth = new Date();
              let firstCurrentMonth = currentMonth.setDate(1);

              var now = new Date();
              if (now.getMonth() == 11) {
                var nextMonth = new Date(now.getFullYear() + 1, 0, 1);
              } else {
                var nextMonth = new Date(
                  now.getFullYear(),
                  now.getMonth() + 1,
                  1,
                );
              }
              let lastCurrentMonth = nextMonth.setDate(0);
              fp.setDate([firstCurrentMonth, lastCurrentMonth], true);
              fp.close();
            } else {
            }
          },
        }),
      ],
      dateFormat: "Y-M-d",
      mode: "range",
    });

    const shortcut_button = document.getElementsByClassName(
      "shortcut-buttons-flatpickr-buttons",
    );
    shortcut_button[0].classList.add("flex", "justify-between", "p-2");

    let shortcut_buttons = document.getElementsByClassName(
      "shortcut-buttons-flatpickr-button",
    );
    for (let i = 0; i < shortcut_buttons.length; i++) {
      shortcut_buttons[i].classList.add(
        "bg-blue-500",
        "hover:bg-blue-700",
        "text-white",
        "font-bold",
        "py-2",
        "px-4",
        "border",
        "border-blue-700",
        "rounded",
      );
    }
  }
}
