import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "tabPanel"];
  static values = { index: Number };
  connect() {
    this.showTab();
  }

  change(event) {
    document.getElementById("tab_report_type").value = "monthly";
    document.getElementById("tab").value = event.target.dataset.tab;
    document.getElementById("submit_select_date_and_report_type").click();
    this.indexValue = this.tabTargets.indexOf(event.target);
    this.showTab();

    let tab_spinners = document.getElementsByClassName("tab-spinner");
    for (let i = 0; i < tab_spinners.length; i++) {
      tab_spinners[i].classList.remove("hidden");
    }
  }

  showTab() {
    this.tabPanelTargets.forEach((element, index) => {
      if (index == this.indexValue) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
      }
    });

    this.tabTargets.forEach((element, index) => {
      const activeClasses = ["bg-blue-900", "bg-opacity-100", "text-white"];
      if (index == this.indexValue) {
        element.classList.add(...activeClasses);
        element.classList.remove("cursor-pointer");
      } else {
        element.classList.remove(...activeClasses);
        element.classList.add("cursor-pointer");
      }
    });
  }
}
